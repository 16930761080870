import React, { useState, useEffect } from "react";

import Main from "components/main";
import {
  Grid,
  Divider,
  Button,
  Box,
  IconButton,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { Edit, Save, Close } from "@material-ui/icons";
import {
  Input,
  InputGroup,
  InputGroupBody,
  InputGroupHeader,
} from "components/inputs";
import { Card, CardHeader, CardNumber } from "components/card";
import BeneficiariesTable from "components/client/beneficiary/table";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "components/modal";
import UserStatusBar from "components/userStatusBar";

import { Containers } from "./style";
import { toast } from "react-toastify";
import api from "services/api";
import { useParams } from "react-router-dom";
import { store } from "store";

import {
  formatCpfCnpj,
  formatDateWithTimezone,
  formatDate,
  formatPhone,
  getBeneficiariesLength,
} from "helper";
import ClientPayment from "components/client/show/payment";
import PaymentHistoryModal from "components/client/payment/paymentHistoryModal";
import { RemoveVacanciesModal } from "components/client/vacancies/removeVacanciesModal";
import { AddVacanciesModal } from "components/client/vacancies/addVacanciesModal";

export default function ClientShow(props) {
  const { profile } = store.getState().user;
  const [client, setClient] = useState({ beneficiaries: [], payments: [] });
  const [openModal, setOpenModal] = useState(false);
  const [openAddVacanciesModal, setOpenAddVacanciesModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openRenewPlanAutomaticallyModal, setOpenRenewPlanAutomaticallyModal] =
    useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [removeVancanciesModal, setRemoveVacanciesModal] = useState(false);

  const [canEditEmail, setCanEditEmail] = useState(false);
  const [disableEmailButtons, setDisableEmailButtons] = useState(false);
  const [email, setEmail] = useState("");

  const [beneficiarySelected, setBeneficiarySelected] = useState({});
  const [updateStatus, setUpdateStatus] = useState(false);

  const { id } = useParams();

  const actives = getBeneficiariesLength(client?.beneficiaries, true);
  const inactives = getBeneficiariesLength(client?.beneficiaries, false);
  const available = client?.plan?.number_users || 0;
  const vacancies = available - actives;

  const [disableType, setDisableType] = useState("immediately");
  const [vacanciesAction, setVacanciesAction] = useState("maintain");

  function toogleCancelModal() {
    setOpenCancelModal(!openCancelModal);
  }

  const [blockPagarmeBtn, setBlockPagarmeBtn] = useState(false);
  function enablePagarme() {
    setBlockPagarmeBtn(true);
    api
      .post("/dynamic-user-plan/enablePagarme", {
        userId: id,
        dynamicUserPlanId: client.plan._id,
      })
      .then(() => {
        toast.success("Cliente Habilitado para cobrança via Pagar.me");
        window.location.reload();
      })
      .catch((e) => {
        setBlockPagarmeBtn(false);
        toast.error(
          "Ocorreu um erro ao tentar habilitar o cliente na pagar.me"
        );
      });
  }

  function toogleRenewPlanAutomaticallyModal() {
    setOpenRenewPlanAutomaticallyModal(!openRenewPlanAutomaticallyModal);
  }

  function toogleRemoveVacancies() {
    setRemoveVacanciesModal(!removeVancanciesModal);
  }

  async function handleCancelUserPlan(plan_id) {
    api
      .post(`/dynamic-user-plan/cancel/${plan_id}`, {})
      .then(() => {
        toast.success("Plano cancelado com sucesso");
        toogleCancelModal();
        window.location.reload(false);
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  }

  async function handleRenewPlanAutomaticallyUserPlan(
    plan_id,
    _renewPlanAutomatically
  ) {
    api
      .post(`/dynamic-user-plan/toogleRenewPlan/${plan_id}`, {
        renewPlanAutomatically: _renewPlanAutomatically,
      })
      .then(() => {
        toast.success(
          `Renovação Automática ${
            _renewPlanAutomatically ? "Ativada" : "Desativada"
          } com sucesso`
        );
        toogleRenewPlanAutomaticallyModal();
        window.location.reload(false);
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  }

  const handleModalChangeStatus = (id, name, status, cpf) => {
    setBeneficiarySelected({
      id,
      name,
      status,
      cpf,
    });
    setUpdateStatus(false);
    setToggleModal(!toggleModal);
  };

  async function changeBeneficiaryStatus(beneficiary) {
    const { id, status, cpf } = beneficiary;
    api
      .put(`/beneficiary/${id}`, {
        status: !status,
        cpf,
        disableType,
        vacanciesAction,
      })
      .then(() => {
        toast.success("Status alterado com sucesso");
        setUpdateStatus(true);
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const cancelEmailChange = () => {
    setEmail(client?.email);
    setCanEditEmail(false);
  };

  const saveEmailChange = async () => {
    setDisableEmailButtons(true);
    try {
      await api.post("user/email", { id, email });
      toast.success("Email atualizado com sucesso");
      client.email = email;
      setCanEditEmail(false);
    } catch (e) {
      toast.error(String(e.response.data.message));
    }
    setDisableEmailButtons(false);
  };

  const reloadClient = async () => {
    const response = await api.get(`/user/clients/${id}`);
    setClient(response.data);
  };

  useEffect(() => {
    async function getClient() {
      const response = await api.get(`/user/clients/${id}`);
      setClient(response.data);
    }
    if (id) getClient();
  }, [id, profile]);

  useEffect(() => {
    setEmail(client?.email);
  }, [client]);

  const renderOptions = () => {
    return (
      <div>
        <RadioGroup
          // defaultValue="immediately"
          value={disableType}
          name="disableType"
          style={{ display: "block" }}
        >
          <FormControlLabel
            style={{ marginTop: "-25px", marginBottom: "-25px" }}
            value="immediately"
            control={<Radio onClick={() => setDisableType("immediately")} />}
            label="Inativar imediatamente."
          />
          <FormControlLabel
            style={{ marginTop: "-25px", marginBottom: "-25px" }}
            value="endCycle"
            control={<Radio onClick={() => setDisableType("endCycle")} />}
            label="Inativar no final do ciclo"
          />
        </RadioGroup>
        <Divider />
        <RadioGroup
          value={vacanciesAction}
          name="vacancies"
          style={{ display: "block" }}
        >
          <FormControlLabel
            style={{ marginTop: "-25px", marginBottom: "-25px" }}
            value="maintain"
            control={<Radio onClick={() => setVacanciesAction("maintain")} />}
            label="Manter a(s) vaga(s)"
          />
          <FormControlLabel
            style={{ marginTop: "-25px", marginBottom: "-25px" }}
            value="remove"
            control={<Radio onClick={() => setVacanciesAction("remove")} />}
            label="Reduzir a(s) vaga(s)."
          />
        </RadioGroup>
      </div>
    );
  };

  return (
    <Main noContainer>
      <Modal show={toggleModal}>
        <ModalHeader
          close={
            !updateStatus
              ? handleModalChangeStatus
              : () => window.location.reload()
          }
        >
          Alterar Status
        </ModalHeader>
        <ModalBody>
          {!updateStatus ? (
            <p>
              Tem certeza que deseja{" "}
              {beneficiarySelected.status === true ? (
                <strong style={{ color: "red" }}>inativar</strong>
              ) : (
                <strong style={{ color: "green" }}>ativar</strong>
              )}{" "}
              {beneficiarySelected.name && (
                <strong>{beneficiarySelected.name}?</strong>
              )}
              {beneficiarySelected.status && renderOptions()}
            </p>
          ) : (
            <p>Status alterado com sucesso.</p>
          )}
        </ModalBody>
        <ModalFooter>
          {!updateStatus ? (
            <>
              <Button variant="outlined" onClick={handleModalChangeStatus}>
                {" "}
                Cancelar{" "}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => changeBeneficiaryStatus(beneficiarySelected)}
              >
                {" "}
                Confirmar{" "}
              </Button>
            </>
          ) : (
            <Button variant="outlined" onClick={() => window.location.reload()}>
              {" "}
              Fechar{" "}
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal show={openCancelModal}>
        <ModalHeader close={toogleCancelModal}>
          {!client.plan?.expiredAtBeforeCancelation
            ? "Cancelar Plano"
            : "Reverter Cancelamento Agendado"}
        </ModalHeader>
        <ModalBody>
          <p>
            {!client.plan?.expiredAtBeforeCancelation
              ? "Deseja cancelar o plano?"
              : "Deseja reverter cancelamento agendado?"}
          </p>
          {client.plan?.expiredAtBeforeCancelation && (
            <p>
              O cancelamento está agendado para o dia:{" "}
              {formatDate(client.expirationDateForCancellationCase)}
            </p>
          )}
          {!client.plan?.expiredAtBeforeCancelation && (
            <>
              {!client.expirationDateForCancellationCase && (
                <p>
                  Plano adquirido há menos de 7 dias, será cancelado
                  imediatamente. Caso o pagamento tenha sido em Cartão de
                  Crédito o Extorno será feito automáticamente.
                </p>
              )}
              {client.expirationDateForCancellationCase && (
                <p>
                  O plano foi adquirido há mais de 7 dias, o cancelamento será
                  agendado para fim do ciclo:{" "}
                  {formatDate(client.expirationDateForCancellationCase)}
                </p>
              )}
              <p>Extornos de boletos devem ser feitos manualmente.</p>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="outlined" onClick={toogleCancelModal}>
            Cancelar Ação
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleCancelUserPlan(client.plan?._id)}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal show={openRenewPlanAutomaticallyModal}>
        <ModalHeader close={toogleRenewPlanAutomaticallyModal}>
          {client.plan?.renewPlanAutomatically ? "Desativar" : "Ativar"}{" "}
          Renovação Automática
        </ModalHeader>
        <ModalBody>
          <p>
            Deseja{" "}
            {client.plan?.renewPlanAutomatically ? "Desativar" : "Ativar"} a
            Renovação Automática do plano?{" "}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outlined"
            onClick={toogleRenewPlanAutomaticallyModal}
          >
            Cancelar
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              handleRenewPlanAutomaticallyUserPlan(
                client.plan?._id,
                !client.plan?.renewPlanAutomatically
              )
            }
          >
            Confirmar
          </Button>
        </ModalFooter>
      </Modal>

      <UserStatusBar
        name={client && client.name ? client.name : ""}
        tel={client && client.telephone ? client.telephone : ""}
        status={client && client.status ? client.status : ""}
        memberDate={
          client && client.createdAt
            ? formatDateWithTimezone(client.createdAt)
            : ""
        }
        termOfContract={client && client.plan && client.plan.expiredAt}
        profile_pic={client && client.profile_pic ? client.profile_pic : ""}
      />
      <Containers maxWidth="100%">
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item>
                <div style={{ position: "relative" }}>
                  <Card>
                    <CardHeader label="Plano" />
                    <CardNumber color="blue">
                      {client && client.plan ? (
                        client.plan.name.length > 16 ? (
                          <div
                            style={{
                              width: "205px",
                              position: "absolute",
                              top: "40px",
                              right: "15px",
                              fontSize: "12px",
                              lineHeight: "125%",
                              paddingRight: "10px",
                              textAlign: "center",
                            }}
                          >
                            {client.plan.name}
                          </div>
                        ) : (
                          client.plan.name
                        )
                      ) : (
                        "Nenhum plano"
                      )}
                    </CardNumber>
                  </Card>
                </div>
              </Grid>
              <Grid item>
                <Card>
                  <CardHeader label="Beneficiários do plano" />
                  <CardNumber color="blue">{available}</CardNumber>
                </Card>
              </Grid>
              <Grid item>
                <Card>
                  <CardHeader label="Beneficiários cadastrados" />
                  <CardNumber color="blue">{actives}</CardNumber>
                </Card>
              </Grid>
              <Grid item>
                <Card>
                  <CardHeader label="Vagas disponíveis" />
                  <CardNumber color="blue">{vacancies}</CardNumber>
                </Card>
              </Grid>
              <Grid item>
                <Card>
                  <CardHeader label="Beneficiários inativos" />
                  <CardNumber color="blue">{inactives}</CardNumber>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={9}>
            <InputGroup>
              <InputGroupHeader>
                <h2>Dados Pessoais</h2>
              </InputGroupHeader>
              <Input
                placeholder="CPF"
                label="CPF/CNPJ"
                disabled
                width="190px"
                value={
                  client && client.cpfcnpj ? formatCpfCnpj(client.cpfcnpj) : ""
                }
              />
              <Input
                placeholder="Nome"
                label="Nome"
                disabled
                width="550px"
                value={client && client.name ? client.name : ""}
              />
              <Input
                placeholder="Tipo"
                label="Tipo"
                disabled
                width="190px"
                value={client?.type}
              />

              <InputGroupBody direction="row">
                <Input
                  placeholder="Email"
                  label="Email"
                  disabled={!canEditEmail}
                  value={email}
                  onChange={handleEmailChange}
                />
                <Box style={{ marginLeft: "20px", marginTop: "45px" }}>
                  {canEditEmail ? (
                    <>
                      <IconButton
                        disabled={disableEmailButtons}
                        onClick={saveEmailChange}
                      >
                        <Save />
                      </IconButton>
                      <IconButton
                        onClick={cancelEmailChange}
                        disabled={disableEmailButtons}
                      >
                        <Close />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton
                      onClick={() => setCanEditEmail(true)}
                      disabled={disableEmailButtons}
                    >
                      <Edit />
                    </IconButton>
                  )}
                </Box>
              </InputGroupBody>

              <Input
                placeholder="Telefone"
                label="Telefone"
                disabled
                width="170px"
                value={
                  client && client.telephone
                    ? formatPhone(client.telephone)
                    : ""
                }
              />
            </InputGroup>

            <InputGroup>
              <InputGroupHeader>
                <h2>Endereço</h2>
              </InputGroupHeader>
              <InputGroupBody direction="row">
                <Input
                  placeholder="Estado"
                  label="Estado"
                  width="100px"
                  disabled
                  value={client && client.state ? client.state : ""}
                />
                <Input
                  placeholder="Cidade"
                  label="Cidade"
                  width="390px"
                  disabled
                  value={client && client.city ? client.city : ""}
                />
              </InputGroupBody>
              <InputGroupBody direction="row">
                <Input
                  placeholder="Bairro"
                  label="Bairro"
                  width="500px"
                  disabled
                  value={
                    client && client.neighborhood ? client.neighborhood : ""
                  }
                />
              </InputGroupBody>
              <InputGroupBody direction="row">
                <Input
                  placeholder="Logradouro"
                  label="Logradouro"
                  width="500px"
                  disabled
                  value={client && client.publicplace ? client.publicplace : ""}
                />
              </InputGroupBody>
              <InputGroupBody direction="row">
                <Input
                  placeholder="Número"
                  label="Número"
                  width="100px"
                  disabled
                  value={client && client.number ? client.number : ""}
                />
                <Input
                  placeholder="Complemento"
                  label="Complemento"
                  width="390px"
                  disabled
                  value={client && client.complement ? client.complement : ""}
                />
              </InputGroupBody>
            </InputGroup>
          </Grid>

          <Grid item xs={3}>
            {vacancies > 0 && (
              <Box style={{ marginTop: 15 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => toogleRemoveVacancies()}
                >
                  Reduzir Vagas
                </Button>
              </Box>
            )}

            {client.plan && (
              <>
                <Box style={{ marginTop: 15 }}>
                  <Button
                    variant="contained"
                    color={"primary"}
                    onClick={() => setOpenAddVacanciesModal(true)}
                  >
                    Adicionar Vagas
                  </Button>
                </Box>
                <Box style={{ marginTop: 15 }}>
                  <Button
                    variant="contained"
                    color={
                      !client.plan.expiredAtBeforeCancelation
                        ? "secondary"
                        : "primary"
                    }
                    onClick={() => toogleCancelModal()}
                  >
                    {!client.plan.expiredAtBeforeCancelation
                      ? "Cancelar Plano"
                      : "Reverter Cancelamento Agendado"}
                  </Button>
                </Box>
                <Box style={{ marginTop: 15 }}>
                  <Button
                    variant="contained"
                    color={
                      client.plan.renewPlanAutomatically
                        ? "secondary"
                        : "primary"
                    }
                    onClick={() => toogleRenewPlanAutomaticallyModal()}
                  >
                    {client.plan.renewPlanAutomatically
                      ? "Desativar"
                      : "Ativar"}{" "}
                    Renovação Automática
                  </Button>
                </Box>
              </>
            )}

            {client.signature &&
              client.signature.status !== "canceled" &&
              client.payments.length > 0 && (
                <>
                  <InputGroup>
                    <InputGroupHeader>
                      <h2>Pagamentos</h2>
                    </InputGroupHeader>
                  </InputGroup>
                  <ClientPayment
                    openModal={() => setOpenModal(true)}
                    payments={client.payments || []}
                  />
                </>
              )}
            {(!client.signature || client.signature.status === "canceled") &&
              client._id && (
                <Box style={{ marginTop: 15 }}>
                  <Button
                    disabled={blockPagarmeBtn}
                    variant="contained"
                    color="Primary"
                    onClick={() => enablePagarme()}
                  >
                    Ativar Pagarme
                  </Button>
                </Box>
              )}
          </Grid>

          <Grid item xs={12}>
            <InputGroup>
              <InputGroupHeader>
                <h2>Beneficiários</h2>
              </InputGroupHeader>
            </InputGroup>
            <BeneficiariesTable
              beneficiaries={client.beneficiaries}
              changeStatus={handleModalChangeStatus}
            />
          </Grid>
        </Grid>
      </Containers>
      <PaymentHistoryModal
        payments={client.payments}
        handleClose={() => setOpenModal(false)}
        open={openModal}
      />
      <RemoveVacanciesModal
        reload={() => reloadClient()}
        open={removeVancanciesModal}
        vacancies={vacancies}
        userPlanId={client.plan?._id}
        handleClose={() => setRemoveVacanciesModal(false)}
      />
      <AddVacanciesModal
        reload={() => reloadClient()}
        open={openAddVacanciesModal}
        userPlanId={client.plan?._id}
        handleClose={() => setOpenAddVacanciesModal(false)}
      />
    </Main>
  );
}
