import React, { useState, useEffect } from "react";
import Main from "components/main";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  NativeSelect,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import useDebounce from "utils/useDebounce";
import { toast } from "react-toastify";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "components/modal";
import { Card, CardHeader, CardNumber } from "components/card";
import { Options } from "../doctors/style";
import Status from "components/status";
import Search from "components/search";
import { useHistory } from "react-router-dom";
import api from "services/api";
import { statusAlias, formatCpfCnpj } from "helper";
import { store } from "store";
import { useDispatch } from "react-redux";
import Loading from "components/Loading";

export default function Clients() {
  //PAGINAÇÃO
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    store.getState().auth.rows_per_page
  );
  const [clientCount, setClientCount] = useState({
    total: 0,
    filtered: 0,
    preRegister: 0,
    active: 0,
    inactive: 0,
    inadimplente: 0,
  });
  const [count, setCount] = useState(0)
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState('');
  const [filter, setFilter] = useState('')
  const [clients, setClients] = useState([]);
  const history = useHistory();
  const [toggleModal, setToggleModal] = useState(false);
  const [clientSelected, setClientSelected] = useState("");
  const [statusSelected, setStatusSelected] = useState("");
  const statusList = [
    { label: "Ativo", value: "ACTIVE" },
    { label: "Inativo", value: "INACTIVE" },
    { label: "Adimplente", value: "ADIMPLENTE" },
    { label: "Inadimplente", value: "INADIMPLENTE" },
  ];
  const [updateStatus, setUpdateStatus] = useState(false);
  const debouncedSearchTerm = useDebounce(getClients, 500);
  const dispatch = useDispatch();

  async function getClients() {
    try {
      setLoading(true)
      const users = await api.get(`/user/paginated-clients?limit=${rowsPerPage}&skip=${page}${search.length > 0 ? '&search=' + search : ''}${filter.length > 0 ? '&status=' + filter : ''}`);
      if (users) {
        setClients(users.data.results);
        setCount(users.data.count);
      }
    } catch {
      toast.error('Erro ao buscar clientes')
    } finally {
      setLoading(false)
    }
  }

  async function getClientCount() {
    try {
      const count = await api.get('/user/count-clients')
      setClientCount(count.data)
      return count;
    } catch {
      toast.error('Erro ao buscar quantidade de clientes')
    }
  }

  useEffect(() => {
    getClientCount()
  }, [])

  useEffect(() => {
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, filter]);

  useEffect(() => {
    debouncedSearchTerm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch({
      type: "@pagination/ROWS_PER_PAGE",
      payload: {
        rows_per_page: parseInt(event.target.value, 10),
      },
    });
    setPage(0);
  };
  //FIM PAGINAÇÃO

  const handleSearch = (e) => {
    setLoading(true)
    setSearch(e.target.value);
    setPage(0);
  };

  const handleFilter = (e) => {
    setLoading(true)
    setFilter(e.target.value);
    setPage(0);
  };

  const handleChangeStatus = () => {
    api
      .put(`/user/status/${clientSelected}`, {
        status: statusSelected,
      })
      .then(() => {
        setUpdateStatus(true);
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const handleModalChangeStatus = (clientId) => {
    // Clear variables
    setUpdateStatus(false);
    setStatusSelected("");
    setClientSelected("");
    if (!updateStatus) document.getElementById("cm-select").selectedIndex = 0;
    // Set values
    setClientSelected(clientId);
    setToggleModal(!toggleModal);
  };

  return (
    <Main maxWidth="xl" style={{ position: "relative", top: "62px" }}>
      <Modal show={toggleModal} onSubmit={() => handleChangeStatus()}>
        <ModalHeader close={handleModalChangeStatus}>
          Alterar Status
        </ModalHeader>
        <ModalBody>
          {!updateStatus ? (
            <select
              id="cm-select"
              onChange={(e) => setStatusSelected(e.target.value)}
            >
              <option value="default">Escolha um status que deseja</option>
              {statusList.map((status) => {
                return (
                  <option key={status.value} value={status.value}>
                    {status.label}
                  </option>
                );
              })}
            </select>
          ) : (
            <span>Status alterado com sucesso!</span>
          )}
        </ModalBody>
        <ModalFooter>
          {!updateStatus && statusSelected ? (
            <>
              <Button variant="outlined" onClick={handleModalChangeStatus}>
                {" "}
                Cancelar{" "}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleChangeStatus}
              >
                {" "}
                Confirmar{" "}
              </Button>
            </>
          ) : (
            <Button
              variant="outlined"
              onClick={
                updateStatus
                  ? () => window.location.reload()
                  : handleModalChangeStatus
              }
            >
              {" "}
              Fechar{" "}
            </Button>
          )}
        </ModalFooter>
      </Modal>
      <Grid container spacing={1} className="mt-3">
        <Grid item>
          <Card>
            <CardHeader label="Pré-Registros" />
            <CardNumber color="red">
              {" "}
              {clientCount.preRegister}
            </CardNumber>
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <CardHeader label="Clientes cadastrados" />
            <CardNumber color="darkGrey"> {clientCount.total} </CardNumber>
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <CardHeader label="Clientes ativos" />
            <CardNumber color="blue">
              {clientCount.active}
            </CardNumber>
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <CardHeader label="Clientes Inadimplentes" />
            <CardNumber color="red">
              {clientCount.inadimplente}
            </CardNumber>
          </Card>
        </Grid>
      </Grid>
      <Options container>
        <Search label={`Buscar clientes`} onChange={handleSearch} />
        <FormControl>
          <InputLabel htmlFor="filter">Filtrar por </InputLabel>
          <NativeSelect
            value={filter}
            onChange={handleFilter}
            inputProps={{
              name: "filter",
              id: "filter",
            }}
            f
          >
            <option aria-label="None" value="" />
            <option value="PRE_REGISTER">Pré-Registro</option>
            <option value="ACTIVE">{statusAlias("Active")}</option>{" "}
            <option value="ADIMPLENTE">Adimplente</option>
            <option value="INADIMPLENTE">Inadimplente</option>
            {/* remover */}
            <option value="INACTIVE">{statusAlias("Inactive")}</option>{" "}
            {/* remover */}
          </NativeSelect>
        </FormControl>
      </Options>
      {
        !loading ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="200" align="center">
                    Nome do Cliente
                  </TableCell>
                  <TableCell width="200" align="center">
                    CPF/CNPJ
                  </TableCell>
                  <TableCell width="200" align="center">
                    Tipo
                  </TableCell>
                  <TableCell width="200" align="center">
                    E-mail
                  </TableCell>
                  <TableCell width="200" align="center">
                    Beneficiários
                  </TableCell>
                  <TableCell width="200" align="center">
                    Status
                  </TableCell>
                  <TableCell width="200" align="center">
                    Opções
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clients
                  .map((client) => (
                    <TableRow key={client._id}>
                      <TableCell align="center">{client.name}</TableCell>
                      <TableCell align="center">
                        {formatCpfCnpj(client.cpfcnpj)}
                      </TableCell>
                      <TableCell align="center">{client?.type}</TableCell>
                      <TableCell align="center">{client.email}</TableCell>
                      <TableCell align="center">
                        {client?.plan?.number_users || 0}
                      </TableCell>
                      <TableCell align="center">
                        <Status
                          status={client.status}
                          onClick={(_) => handleModalChangeStatus(client._id)}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="outlined"
                          onClick={() => history.push(`/clientes/${client._id}`)}
                        >
                          Detalhes
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    // colSpan={3}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "Itens por página" },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) : (
          <Loading />
        )
      }
    </Main>
  );
}
