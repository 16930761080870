import React, { useEffect, useState } from "react";
import Main from "components/main";
import { Card, CardHeader } from "components/card";
import ClientPlanCard from "components/client/plan/card";
import {
  ClientOtherPlansCard,
  OtherPlans,
  NewPlans,
  CardInfoWrapper,
} from "./style";
import api from "services/api";
import { Grid } from "@material-ui/core";
import PlanDetailsModal from "components/client/plan/planDetailsModal";
import AvaliablePlansModal from "components/client/plan/avaliablePlansModal";
import { useHistory } from "react-router-dom";

export default function Plans() {
  const [userPlan, setUserPlan] = useState({});
  const [clientType, setClientType] = useState("");
  const [paymentInfo, setPaymentInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [plans, setUserPlans] = useState([]);
  const [clientData, setClientData] = useState(null);
  const [open, setOpen] = useState({ details: false, avaliable: false });
  const history = useHistory();

  useEffect(() => {
    const getUserPlan = async () => {
      try {
        const client = await api.get("/user/clients/self");
        setClientData(client.data);
        setUserPlan(client.data.plan ? client.data.plan : {});
        setClientType(client.data.type);
        const responseDynamicPlan = await api.get("/dynamic-plan");
        const responsePlan = await api.get("/plan");

        let plan_id = null;

        if (client.data.plan && client.data.plan.plan_id)
          plan_id = client.data.plan.plan_id;
        else if (client.data.plan && client.data.plan.dynamic_plan_id)
          plan_id = client.data.plan.dynamic_plan_id;

        const combinedPlans = [
          ...responsePlan.data,
          ...responseDynamicPlan.data,
        ];
        setUserPlans(
          combinedPlans.filter((val) => {
            let today = new Date();
            const todayOffset = new Date(today.setDate(today.getDate() - 1));
            const resetHours = new Date(todayOffset.setHours(21, 0, 0, 0));
            const start = new Date(val.start_date);
            const end = new Date(val.expired_date);
            return (
              val._id !== plan_id &&
              start <= resetHours &&
              resetHours <= end &&
              (client.data.type === "PJ"
                ? client.data.type === val.type && val.min_users !== undefined
                : client.data.type === val.type || val.type === "MULTIPLAN") &&
              val.status === "ACTIVE"
            );
          })
        );
        if (client.data?.plan?.gateway_signature_id) {
          const getPaymentInfo = await api.get(
            "payment-gateway/user-plan/" + client.data.plan.gateway_signature_id
          );
          setPaymentInfo(getPaymentInfo.data);
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getUserPlan();
  }, []);

  const redirectToBuyPlan = (plan) => {
    history.push("/planos/compare", {
      plans: [{ _id: plan._id, type: plan.type }],
    });
  };

  const highestOff = (plan) => {
    const bigger = Math.max(plan.semestral, plan.trimestral, plan.anuality);
    return (plan.mensality * bigger) / 100;
  };

  return (
    <>
      <Main maxWidth="xl">
        {userPlan && userPlan._id && (
          <>
            <h4>Seu plano</h4>
            <Card xl onClick={() => setOpen({ ...open, details: true })}>
              <CardHeader />
              <ClientPlanCard
                name={userPlan.name ? userPlan.name : ""}
                hiredAt={userPlan.createdAt ? userPlan.createdAt : ""}
                validity={userPlan.expiredAt ? userPlan.expiredAt : ""}
                mensality={
                  userPlan.value
                    ? (userPlan.value * (userPlan.number_users || 0))
                        .toFixed(2)
                        .replace(".", ",")
                    : ""
                }
                recurrence={userPlan.period}
                openCompareModal={() =>
                  setOpen({ details: false, avaliable: true })
                }
                nextPaymentDate={paymentInfo.current_period_end}
                expirationDateForCancellationCase={
                  clientData?.expirationDateForCancellationCase
                }
              />
            </Card>
          </>
        )}
        {userPlan._id && !loading && (
          <OtherPlans>
            <h4>Outros planos disponíveis</h4>
            <span>Você poderá alterar seu plano quando desejar.</span>
          </OtherPlans>
        )}
        {!userPlan._id && !loading && (
          <NewPlans>
            {clientType === "PJ" ? (
              <h4 style={{ margin: "0px" }}>
                PASSO 3: Clique no Plano escolhido.
              </h4>
            ) : (
              <h4>PASSO 1: Escolha seu plano</h4>
            )}
            <button onClick={() => setOpen({ ...open, avaliable: true })}>
              Comparar
            </button>
          </NewPlans>
        )}
        <Grid
          style={{ marginTop: "16px" }}
          container
          spacing={2}
          alignItems="center"
          alignContent="center"
        >
          {plans.map((plan) => (
            <Grid
              style={{ cursor: "pointer" }}
              onClick={() => redirectToBuyPlan(plan)}
              item
              md={4}
              xs={12}
              sm={6}
              key={plan._id}
            >
              <ClientOtherPlansCard elevation={0}>
                <CardInfoWrapper>
                  <div>
                    <h6>Plano</h6>
                    <p style={{ color: "#2E5AAC" }}>{plan.name}</p>
                  </div>
                  <div style={{ color: "" }}>
                    <h6>
                      {plan.type === "PF"
                        ? "Máximo de beneficiários"
                        : "Número de beneficiários"}
                    </h6>
                    <p style={{ color: "#6B6C7E" }}>
                      {plan.type === "PF"
                        ? plan.number_users
                        : plan.min_users > 0 && plan.max_users === 0
                        ? plan.min_users + " + "
                        : plan.min_users === 0 && plan.max_users > 0
                        ? plan.max_users + " - "
                        : plan.min_users === 0 && plan.max_users === 0
                        ? "Customizado"
                        : `${plan.min_users} - ${plan.max_users}`}
                    </p>
                  </div>
                </CardInfoWrapper>
                <CardInfoWrapper>
                  <Grid style={{ textAlign: "right" }}>
                    <h6>
                      {plan.type === "PF" ? "Valor Mensal" : "Valor por pessoa"}
                    </h6>
                    <p style={{ color: "#287D3C" }}>
                      R$ {plan.mensality.toFixed(2).replace(".", ",")}
                    </p>
                  </Grid>
                  {plan.type === "PF" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                      xs={12}
                    >
                      <h6>Valor por pessoa</h6>
                      <p style={{ fontSize: "12px", marginTop: 0 }}>
                        R${" "}
                        {(plan.mensality / plan.number_users)
                          .toFixed(2)
                          .replace(".", ",")}
                      </p>
                    </div>
                  )}

                  <div style={{ textAlign: "right" }}>
                    <h6>Desconto de até</h6>
                    <p style={{ color: "#3089C3" }}>
                      R$ {highestOff(plan).toFixed(2).replace(".", ",")}
                    </p>
                  </div>
                </CardInfoWrapper>
              </ClientOtherPlansCard>
            </Grid>
          ))}
        </Grid>
      </Main>
      <PlanDetailsModal
        open={open.details}
        handleClose={() => setOpen({ ...open, details: false })}
        name={userPlan.name}
        mensality={userPlan.value ? userPlan.value : 0}
        period={userPlan.period}
        plan={userPlan.plan_id ? userPlan.plan_id : userPlan.dynamic_plan_id}
        beneficiaries={userPlan.number_users}
        onSubmit={(e) => {
          e.preventDefault();
          setOpen({ details: false, avaliable: true });
        }}
      />
      <AvaliablePlansModal
        open={open.avaliable}
        handleClose={() => setOpen({ ...open, avaliable: false })}
        name={userPlan.name}
        plans={[...plans]}
      />
    </>
  );
}
