import React from "react";
import LoadingGif from "../../assets/icons/LoadingBlue.gif";
import { LoadingWrapper } from "./style";

export default function Loading() {
  return (
    <LoadingWrapper>
      <img src={LoadingGif} alt="loading icon" />
    </LoadingWrapper>
  );
}