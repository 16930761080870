import React from "react";
import { Grid } from "@material-ui/core";

import { PlanPaper } from "./style";
import { CardNumber } from "components/card";
import { formatDateWithTimezone } from "../../../../helper";

export default function ClientPlanCard({
  name,
  hiredAt,
  validity,
  change,
  mensality,
  recurrence = "",
  openCompareModal,
  nextPaymentDate,
  expirationDateForCancellationCase,
}) {
  // const nextPayment = (expirationDate) => {
  //   let hired = payments.length > 0 ? getNextPayment(payments) : new Date(hiredAt)
  //   if(hired) {
  //     const expiration = new Date(expirationDate)
  //     const nextBilling = new Date(hired.setDate(hired.getDate() + 30))
  //     if(nextBilling > expiration)
  //       return null
  //     else
  //       return formatDate(nextBilling)
  //   }
  //   else
  //     return null

  // }

  // const getNextPayment = (payments=[]) => {
  //   const max = new Date(
  //     Math.max.apply(null,payments
  //       .filter(val=>val.status==="paid")
  //       .map(val=>new Date(val.createdAt)))
  //   )
  //   return max
  // }

  return (
    <PlanPaper>
      <Grid container>
        <Grid item md={4} xs={8}>
          <h4>Nome do plano</h4>
          <CardNumber className="plan-name" color="darkGreen">
            {name}
          </CardNumber>
        </Grid>
        <Grid item md={4} xs={4}>
          <h4>Contratado em</h4>
          <CardNumber className="plan-hiredOn" color="darkGrey">
            {formatDateWithTimezone(hiredAt)}
          </CardNumber>
        </Grid>
        <Grid item md={4} xs={8}>
          <h4>Plano</h4>
          <CardNumber className="plan-period" color="darkGrey">
            {recurrence.charAt(0).toUpperCase() +
              recurrence.slice(1).toLowerCase()}
          </CardNumber>
        </Grid>
        <Grid item md={4} xs={4}>
          <h4>Valor mensal</h4>
          <CardNumber className="plan-monthValue" color="darkGrey">
            R$ {mensality}
          </CardNumber>
        </Grid>
        {nextPaymentDate && !expirationDateForCancellationCase && (
          <Grid item md={4} xs={8}>
            <h4>Prox. Faturamento</h4>
            <CardNumber className="plan-nextPayment" color="darkGrey">
              {formatDateWithTimezone(nextPaymentDate, true)}
            </CardNumber>
          </Grid>
        )}

        {expirationDateForCancellationCase && (
          <Grid item md={4} xs={8}>
            <h4>Cancelamento Agendado</h4>
            <CardNumber className="plan-nextPayment" color="darkGrey">
              {formatDateWithTimezone(expirationDateForCancellationCase)}
            </CardNumber>
          </Grid>
        )}

        <Grid item md={4} xs={4}>
          <h4>Vigência</h4>
          <CardNumber className="plan-validity" color="darkGrey">
            {formatDateWithTimezone(validity)}
          </CardNumber>
        </Grid>
        {/* <Hidden mdUp>
            <Grid item xs={12}>
              <Manage type="button" onClick={()=>openCompareModal()}>Gerenciar</Manage>
            </Grid>
          </Hidden> */}
      </Grid>
    </PlanPaper>
  );
}
