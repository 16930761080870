import React, { useEffect, useState } from "react";

import {
  Grid,
  Divider,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

import Main from "components/main";

import api from "services/api";
import { store } from "store";
import { Card, CardHeader, CardNumber } from "components/card";
import Search from "components/search";
import { BeneficiariesAdmTable } from "./table";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "components/modal";
import { toast } from "react-toastify";
import useDebounce from "utils/useDebounce";
import Loading from "components/Loading";

export const BeneficiariesAdmin = () => {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [count, setCount] = useState(0);
  const [beneficiariesCount, setBeneficiariesCount] = useState({
    total: 0,
    active: 0,
  });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(
    store.getState().auth.rows_per_page
  );
  const [loading, setLoading] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [beneficiarySelected, setBeneficiarySelected] = useState({});
  const [updateStatus, setUpdateStatus] = useState(false);
  const debouncedSearchTerm = useDebounce(getBeneficiaries, 500);

  async function getBeneficiaries() {
    try {
      setLoading(true);
      const response = await api.get(
        `/beneficiary/paginated?limit=${itemsPerPage}&skip=${page}${
          search.length > 0 ? "&search=" + search : ""
        }`
      );
      setBeneficiaries(response.data.results);
      setCount(response.data.count);
    } catch {
      toast.error("Erro ao buscar beneficiários");
    } finally {
      setLoading(false);
    }
  }

  async function getBeneficiariesCount() {
    try {
      const count = await api.get("/beneficiary/count-beneficiaries");
      setBeneficiariesCount(count.data);
      return count;
    } catch {
      toast.error("Erro ao buscar quantidade de beneficiários");
    }
  }

  useEffect(() => {
    getBeneficiariesCount();
  }, []);

  useEffect(() => {
    getBeneficiaries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, page]);

  useEffect(() => {
    debouncedSearchTerm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleSearch = (e) => {
    setLoading(true);
    setSearch(e.target.value);
    setPage(0);
  };

  const handleModalChangeStatus = (beneficiary) => {
    setBeneficiarySelected(beneficiary);
    setUpdateStatus(false);
    setToggleModal(!toggleModal);
  };

  async function changeBeneficiaryStatus(beneficiary) {
    api
      .put(`/beneficiary/${beneficiary._id}`, {
        status: !beneficiary.status,
        cpf: beneficiary.cpf,
      })
      .then(() => {
        setUpdateStatus(true);
        toast.success("Status alterado com sucesso");
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  }

  const renderOptions = () => {
    return (
      <div>
        <RadioGroup
          defaultValue="immediately"
          name="disableType"
          style={{ display: "block" }}
        >
          <FormControlLabel
            style={{ marginTop: "-25px", marginBottom: "-25px" }}
            value="immediately"
            control={<Radio />}
            label="Inativar imediatamente."
          />
          <FormControlLabel
            style={{ marginTop: "-25px", marginBottom: "-25px" }}
            value="endCycle"
            control={<Radio />}
            label="Inativar no final do ciclo"
          />
        </RadioGroup>
        <Divider />
        <RadioGroup
          defaultValue="maintein"
          name="vacancies"
          style={{ display: "block" }}
        >
          <FormControlLabel
            style={{ marginTop: "-25px", marginBottom: "-25px" }}
            value="maintein"
            control={<Radio />}
            label="Manter as vagas"
          />
          <FormControlLabel
            style={{ marginTop: "-25px", marginBottom: "-25px" }}
            value="remove"
            control={<Radio />}
            label="Reduzir as vagas."
          />
        </RadioGroup>
      </div>
    );
  };

  return (
    <Main maxWidth="xl">
      <Modal show={toggleModal}>
        <ModalHeader
          close={
            !updateStatus
              ? handleModalChangeStatus
              : () => window.location.reload()
          }
        >
          Alterar Status
        </ModalHeader>
        <ModalBody>
          {!updateStatus ? (
            <p>
              Tem certeza que deseja{" "}
              {beneficiarySelected.status === true ? (
                <>
                  <strong style={{ color: "red" }}>inativar</strong>
                </>
              ) : (
                <strong style={{ color: "green" }}>ativar</strong>
              )}{" "}
              {beneficiarySelected.name && (
                <strong>{beneficiarySelected.name}?</strong>
              )}
              {beneficiarySelected.status && renderOptions()}
            </p>
          ) : (
            <p>Status alterado com sucesso.</p>
          )}
        </ModalBody>
        <ModalFooter>
          {!updateStatus ? (
            <>
              <Button variant="outlined" onClick={handleModalChangeStatus}>
                {" "}
                Cancelar{" "}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => changeBeneficiaryStatus(beneficiarySelected)}
              >
                {" "}
                Confirmar{" "}
              </Button>
            </>
          ) : (
            <Button variant="outlined" onClick={() => window.location.reload()}>
              {" "}
              Fechar{" "}
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Grid container className="mt-3">
        <Card>
          <CardHeader label="Beneficiários Cadastrados" />
          <CardNumber color="blue">{beneficiariesCount.total}</CardNumber>
        </Card>
        <Card>
          <CardHeader label="Beneficiários Ativos" />
          <CardNumber color="green">{beneficiariesCount.active}</CardNumber>
        </Card>
      </Grid>

      <Grid container style={{ marginTop: "30px" }}>
        <Search label={`Buscar beneficiarios`} onChange={handleSearch} />
      </Grid>

      <div style={{ marginTop: "30px" }}>
        {loading ? (
          <Loading />
        ) : (
          <BeneficiariesAdmTable
            count={count}
            beneficiaries={beneficiaries}
            page={page}
            setPage={setPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            changeStatus={handleModalChangeStatus}
          />
        )}
      </div>
    </Main>
  );
};
