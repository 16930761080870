import React from "react";
import {
  Button,
  Collapse,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { formatCpfCnpj, formatDate, formatPhone } from "helper";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
// import ClearIcon from "@material-ui/icons/Clear";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useHistory } from "react-router-dom";
import { store } from "store";

export default function BeneficiariesTable({
  beneficiaries = [],
  addDependent,
  dynamicPlan,
  changeStatus,
}) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" width="50"></TableCell>
            <TableCell align="center" width="250">
              Nome
            </TableCell>
            <TableCell align="center" width="250">
              E-mail
            </TableCell>
            <TableCell align="center" width="250">
              CPF
            </TableCell>
            <TableCell align="center" width="200">
              Data de nascimento
            </TableCell>
            <TableCell align="center" width="200">
              Celular
            </TableCell>
            <TableCell align="center" width="200">
              Gênero
            </TableCell>

            <TableCell align="center" width="200">
              Opções
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {beneficiaries.map((beneficiary) => (
            <Row
              dynamicPlan={dynamicPlan}
              beneficiary={beneficiary}
              addDependent={addDependent}
              changeStatus={changeStatus}
            ></Row>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function Row(props) {
  const { beneficiary } = props;
  const { profile } = store.getState().user;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const history = useHistory();

  const backGroundColor = (status, cancelScheduled, enableScheduled) => {
    if (!status && enableScheduled) return "#C1E6FF";
    if (!status) return "#FEEFEF";
    else if (cancelScheduled) return "#FFFDD3";
    else return "inherit";
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        style={{
          backgroundColor: backGroundColor(
            beneficiary.status,
            beneficiary.cancelScheduled,
            beneficiary.enableScheduled
          ),
        }}
      >
        <TableCell width="50">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" width="250">
          {beneficiary.name}
        </TableCell>
        <TableCell align="center" width="250">
          {beneficiary.email}
        </TableCell>
        <TableCell align="center" width="250">
          {formatCpfCnpj(beneficiary.cpf)}
        </TableCell>
        <TableCell align="center" width="200">
          {formatDate(beneficiary.birth_date)}
        </TableCell>
        <TableCell align="center" width="200">
          {beneficiary.telephone
            ? formatPhone(beneficiary.telephone)
            : "Não inserido"}
        </TableCell>
        <TableCell align="center" width="200">
          {beneficiary.genre.charAt(0).toUpperCase() +
            beneficiary.genre.slice(1).toLowerCase()}
        </TableCell>
        <TableCell align="center" width="200">
          {(profile === "GESTOR" || profile === "OPERADOR") && (
            <Button
              variant="outlined"
              onClick={() =>
                props.changeStatus(
                  beneficiary._id,
                  beneficiary.name,
                  beneficiary.status,
                  beneficiary.cpf
                )
              }
            >
              {beneficiary.status ? "INATIVAR" : "ATIVAR"}
            </Button>
          )}

          {props.addDependent && (
            <button
              onClick={() => {
                const url = props.dynamicPlan
                  ? "/beneficiarios/add-multiple-dependents/"
                  : "/beneficiarios/add/";
                return history.push(url + beneficiary._id);
              }}
              style={{
                backgroundColor: "transparent",
                color: "#4B9BFF",
                cursor: "pointer",
                border: "none",
              }}
            >
              Incluir Dependente
            </button>
          )}
        </TableCell>
        <TableCell>
          {beneficiary.status && beneficiary.cancelScheduled && (
            <Tooltip
              title={`Beneficiário com cancelamento agendado para ${new Date(
                beneficiary.cancelScheduled
              ).toLocaleDateString()}, ${
                beneficiary.cancelWithVacancies
                  ? "com redução de vagas"
                  : "mantendo as vagas inalteradas"
              }`}
            >
              <Info color="primary"></Info>
            </Tooltip>
          )}
          {!beneficiary.status && beneficiary.enableScheduled && (
            <Tooltip
              title={`Beneficiário com ativamento agendado para ${new Date(
                beneficiary.enableScheduled
              ).toLocaleDateString()}`}
            >
              <Info color="primary"></Info>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0, margin: 0 }} width="100%" colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {beneficiary.dependents.map((dependent) => (
              <TableRow
                className={classes.root}
                style={{
                  background: backGroundColor(
                    dependent.status,
                    beneficiary.cancelScheduled
                      ? beneficiary.cancelScheduled
                      : dependent.cancelScheduled,
                    dependent.enableScheduled
                  ),
                }}
              >
                <TableCell width="50"></TableCell>
                <TableCell align="center" width="250">
                  {dependent.name}
                </TableCell>
                <TableCell align="center" width="250">
                  {dependent.email}
                </TableCell>
                <TableCell align="center" width="250">
                  {formatCpfCnpj(dependent.cpf)}
                </TableCell>
                <TableCell align="center" width="200">
                  {formatDate(dependent.birth_date)}
                </TableCell>
                <TableCell align="center" width="200">
                  {dependent.telephone
                    ? formatPhone(dependent.telephone)
                    : "Não inserido"}
                </TableCell>
                <TableCell align="center" width="200">
                  {dependent.genre.charAt(0).toUpperCase() +
                    dependent.genre.slice(1).toLowerCase()}
                </TableCell>
                <TableCell align="center" width="200">
                  {(profile === "GESTOR" || profile === "OPERADOR") && (
                    <Button
                      variant="outlined"
                      onClick={() =>
                        props.changeStatus(
                          dependent._id,
                          dependent.name,
                          dependent.status,
                          dependent.cpf
                        )
                      }
                    >
                      {dependent.status ? "INATIVAR" : "ATIVAR"}
                    </Button>
                  )}
                </TableCell>
                <TableCell>
                  {dependent.status && dependent.cancelScheduled && (
                    <Tooltip
                      title={`Beneficiário com cancelamento agendado para ${new Date(
                        dependent.cancelScheduled
                      ).toLocaleDateString()}, ${
                        dependent.cancelWithVacancies
                          ? "com redução de vagas"
                          : "mantendo as vagas inalteradas"
                      }`}
                    >
                      <Info color="primary"></Info>
                    </Tooltip>
                  )}
                  {!dependent.status && dependent.enableScheduled && (
                    <Tooltip
                      title={`Beneficiário com ativamento agendado para ${new Date(
                        dependent.enableScheduled
                      ).toLocaleDateString()}`}
                    >
                      <Info color="primary"></Info>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});
