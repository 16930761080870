import styled from "styled-components";

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;

  img {
    width: 50px;
  }
`