import React, { useState } from "react";
import Modal, { ModalBody, ModalHeader, ModalFooter } from "../../modal/index";
import { toast } from "react-toastify";

import { Input } from "../../inputs";
import api from "services/api";

export const AddVacanciesModal = ({
  open,
  handleClose,
  userPlanId,
  reload,
}) => {
  const [toIncrease, setToIncrease] = useState(1);
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();

    try {
      setDisabled(true);
      await api.post("/dynamic-user-plan/increaseVacancies", {
        userPlan_id: userPlanId,
        toIncrease,
      });
      await reload();
      toast.success("Número de vagas atualizado com sucesso");
      handleClose();
      setDisabled(false);
    } catch (err) {
      setDisabled(false);
      toast.error(err.response.data.message);
    }
  };

  const valueChange = (e) => {
    setToIncrease(Number(e.target.value));
  };

  const validate = () => {
    if (toIncrease < 1) setToIncrease(1);
  };

  return (
    <Modal show={open} close={handleClose} onSubmit={(e) => handleSubmit(e)}>
      <ModalHeader close={handleClose}>Adicionar Vagas</ModalHeader>
      <ModalBody>
        <p>
          Informe a quantidade de vagas que deseja acrescentar para este
          cliente:
        </p>
        <Input
          width={"100px"}
          type="number"
          value={toIncrease}
          onChange={(e) => valueChange(e)}
          onBlur={validate}
          min={1}
        />
      </ModalBody>
      <ModalFooter cancel={handleClose} next="Confirmar" disabled={disabled} />
    </Modal>
  );
};
